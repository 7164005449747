import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "redux/errors/errorActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ShowError = () => {
  const { errors } = useSelector((state) => state.error);
  const dispatch = useDispatch();
  useEffect(() => {
    errors.forEach((error) => {
      if (error.isOpen) {
        const timeoutId = setTimeout(() => {
          dispatch(clearError(error));
        }, error.duration);

        return () => clearTimeout(timeoutId);
      }
    });
  }, [dispatch, errors]);
  const handleClose = (error) => {
    dispatch(clearError(error));
  };
  return (
    <div style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 9999 }}>
      {errors.map((error, index) => (
        <Snackbar
          key={index}
          open={Boolean(error.isOpen)}
          autoHideDuration={error.duration}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          style={{ marginBottom: 8, position: "relative" }}>
          <Alert onClose={() => handleClose(error)} severity={error.severity}>
            {error.msg}
          </Alert>
        </Snackbar>
      ))}
    </div>
  );
};
export default ShowError;