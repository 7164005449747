export const SET_USER_DATA = "SET_USER_DATA";
export const SET_SIGNUP_PAYLOAD = "SET_SIGNUP_PAYLOAD";
export const CLEAR_SIGNUP_PAYLOAD = "CLEAR_SIGNUP_PAYLOAD";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_ADDRESSES = "SET_ADDRESSES";
export const SET_LOADING = "SET_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const CREAT_USER_ACCOUNT_REQUEST = "CREAT_USER_ACCOUNT_REQUEST";
export const CREAT_USER_ACCOUNT_SUCCESS = "CREAT_USER_ACCOUNT_SUCCESS";
export const CREAT_USER_ACCOUNT_FAILURE = "CREAT_USER_ACCOUNT_FAILURE";
export const SET_STEPS = "SET_STEPS";
export const VERIFICATION_REQUEST = "VERIFICATION_REQUEST";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_FAILURE = "VERIFICATION_FAILURE";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const INVITE_LANDLORD_REQUEST = "INVITE_LANDLORD_REQUEST";
export const INVITE_LANDLORD_SUCCESS = "INVITE_LANDLORD_SUCCESS";
export const INVITE_LANDLORD_FAILURE = "INVITE_LANDLORD_FAILURE";
export const CLEAR_LOCALSTORAGE_SIGNUP_COMPLETE = "CLEAR_LOCALSTORAGE_SIGNUP_COMPLETE";
export const RESEND_LINK = "RESEND_LINK";
export const SET_ERRORS = "SET_ERRORS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const VERIFY_FORGOT_TOKEN_REQUEST = "VERIFY_FORGOT_TOKEN_REQUEST";
export const VERIFY_FORGOT_TOKEN_SUCCESS = "VERIFY_FORGOT_TOKEN_SUCCESS";
export const VERIFY_FORGOT_TOKEN_FAILURE = "VERIFY_FORGOT_TOKEN_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";
export const SET_MY_DETAILS = "SET_MY_DETAILS";
export const GET_ACCOUNT_DETAILS_REQUEST = "GET_ACCOUNT_DETAILS_RESQUEST";
export const GET_ACCOUNT_DETAILS_SUCCESS = "GET_ACCOUNT_DETAILS_SUCCESS";
export const GET_ACCOUNT_DETAILS_FAILURE = "GET_ACCOUNT_DETAILS_FAILURE";
export const GET_SUBSCRIPTION_DETAILS_REQUEST = "GET_SUBSCRIPTION_DETAILS_REQUEST";
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = "GET_SUBSCRIPTION_DETAILS_SUCCESS";
export const GET_SUBSCRIPTION_DETAILS_FAILURE = "GET_SUBSCRIPTION_DETAILS_FAILURE";
export const NEED_PASSWORD_CHANGE = "NEED_PASSWORD_CHANGE";