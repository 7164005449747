import { CLEAR_ERROR, SET_ERROR, SET_EXPIRE, CLEAR_EXPIRE } from "./errorTypes";

const initialsState = {
  errors: [],
  expire: {msg: '', isOpen: false}
};
const errorReducer = (state = initialsState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        errors: [...(state.errors),...action.payload]
      };

    case CLEAR_ERROR:
      return {
        ...state,
        errors: action.payload ? state.errors.filter((e) => e.id !== action.payload.id || e.msg !== action.payload.msg) : []
      };
    case SET_EXPIRE:
      return {
        ...state,
        expire: action.payload
      };
    case CLEAR_EXPIRE:
      return{
        ...state,
        expire: initialsState.expire
      };
    default:
      return state;
  }
};

export default errorReducer;
