import { api } from "_endpoints/api";
import http from "../../axios";
import {
  LISTING_COMPLIANCES_REQUEST,
  LISTING_COMPLIANCES_FAILURE,
  LISTING_COMPLIANCES_SUCCESS,
  ADD_COMPLIANCE_REQUEST,
  ADD_COMPLIANCE_SUCCESS,
  ADD_COMPLIANCE_FAILURE,
  SET_COMPLIANCE_PROPERTY_CATEGORY,
  SET_SINGLE_COMPLIANCE,
  DELETE_COMPLIANCE_REQUEST,
  DELETE_COMPLIANCE_FAILURE,
  DELETE_COMPLIANCE_SUCCESS,
  GET_ALL_COMPLIANCES_REQUEST,
  GET_ALL_COMPLIANCES_SUCCESS,
  GET_ALL_COMPLIANCES_FAILURE,
  SET_SINGLE_COMPLIANCE_ALL,
  UPDATE_COMPLIANCE_REQUEST,
  UPDATE_COMPLIANCE_SUCCESS,
  UPDATE_COMPLIANCE_FAILURE,
  ARCHIVE_FILE_REQUEST,
  ARCHIVE_FILE_SUCCESS,
  ARCHIVE_FILE_FAILURE,
  UNARCHIVE_FILE_REQUEST,
  UNARCHIVE_FILE_SUCCESS,
  UNARCHIVE_FILE_FAILURE
} from "./types";
import { setError } from "redux/errors/errorActions";

export const getCompliances = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LISTING_COMPLIANCES_REQUEST });
      const response = await http.get(api.compliance.getCompliances);
      dispatch({ type: LISTING_COMPLIANCES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: LISTING_COMPLIANCES_FAILURE, payload: error.response.data.message });
    }
  };
};

export const addComplinace = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_COMPLIANCE_REQUEST });
      const response = await http.post(api.compliance.addCompliance, payload);
      dispatch({ type: ADD_COMPLIANCE_SUCCESS, payload: response.data });
      dispatch(setError([{ msg: "Compliance record added successfully", isOpen: true, duration: 6000, severity: "success" }]));
      return 200;
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: ADD_COMPLIANCE_FAILURE, payload: error.response.data.message });
      return 400;
    }
  };
};

export const deleteComplinace = ({ id, propertyId }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_COMPLIANCE_REQUEST });
      await http.delete(api.compliance.deleteCompliance(id));
      dispatch({ type: DELETE_COMPLIANCE_SUCCESS, payload: { id, propertyId } });
      dispatch(setError([{ msg: "Compliance record deleted successfully", isOpen: true, duration: 6000, severity: "success" }]));
      return;
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: DELETE_COMPLIANCE_FAILURE, payload: error.response.data.message });
    }
  };
};

export const updateCompliace = ({ id, payload }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_COMPLIANCE_REQUEST });
      const response = await http.patch(api.compliance.updateCompliance(id), payload);
      dispatch({ type: UPDATE_COMPLIANCE_SUCCESS, payload: response.data });
      dispatch(setError([{ msg: "Compliance record updated successfully", isOpen: true, duration: 6000, severity: "success" }]));
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: UPDATE_COMPLIANCE_FAILURE, payload: error.response.data.message });
    }
  };
};

export const archiveCompliance = ({ id }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ARCHIVE_FILE_REQUEST });
      const response = await http.post(api.compliance.archiveCompliance(id));
      dispatch({ type: ARCHIVE_FILE_SUCCESS, payload: response.data });
      dispatch(setError([{ msg: "Document archived successfully", isOpen: true, duration: 6000, severity: "success" }]));
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: ARCHIVE_FILE_FAILURE, payload: error.response.data.message });
    }
  };
};

export const setCompliancePropertyCategory = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_COMPLIANCE_PROPERTY_CATEGORY, payload: id });
  };
};

export const setSingleCompliance = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_SINGLE_COMPLIANCE, payload: id });
  };
};

export const setSingleComplianceByAll = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_SINGLE_COMPLIANCE_ALL, payload: id });
  };
};
export const getAllCompliances = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_COMPLIANCES_REQUEST });
      const response = await http.get(api.compliance.getAllCompliances());
      dispatch({ type: GET_ALL_COMPLIANCES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: GET_ALL_COMPLIANCES_FAILURE, payload: error.response.data.message });
    }
  };
};
export const unArchiveCompliance = (complianceId, archiveId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UNARCHIVE_FILE_REQUEST });
      const response = await http.post(api.compliance.unArchiveCompliance(), { complianceId, archiveId });
      dispatch({ type: UNARCHIVE_FILE_SUCCESS, payload: response.data });
      dispatch(setError([{ msg: "Document unarchived successfully", isOpen: true, duration: 6000, severity: "success" }]));
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: UNARCHIVE_FILE_FAILURE, payload: error.response.data.message });
    }
  };
};
