import { CLEAR_ERROR, SET_ERROR, SET_EXPIRE, CLEAR_EXPIRE } from "./errorTypes";

export const setError = (payload) => {
  return {
    type: SET_ERROR,
    payload: payload
  };
};

export const clearError = (payload) => {
  return {
    type: CLEAR_ERROR,
    payload: payload
  };
};

export const setExpire = (payload) => {
  return {
    type: SET_EXPIRE,
    payload: payload
  };
};

export const clearExpire = (payload) => {
  return {
    type: CLEAR_EXPIRE,
    payload: payload
  };
};
