export const LISTING_COMPLIANCES_REQUEST = "LISTING_COMPLIANCES_REQUEST";
export const LISTING_COMPLIANCES_SUCCESS = "LISTING_COMPLIANCES_SUCCESS";
export const LISTING_COMPLIANCES_FAILURE = "LISTING_COMPLIANCES_FAILURE";
export const ADD_COMPLIANCE_REQUEST = "ADD_COMPLIANCE_REQUEST";
export const ADD_COMPLIANCE_SUCCESS = "ADD_COMPLIANCE_SUCCESS";
export const ADD_COMPLIANCE_FAILURE = "ADD_COMPLIANCE_FAILURE";
export const GET_COMPLIANCE_BY_ID_REQUEST = "GET_COMPLIANCE_BY_ID_REQUEST";
export const GET_COMPLIANCE_BY_ID_SUCCESS = "GET_COMPLIANCE_BY_ID_SUCCESS";
export const GET_COMPLIANCE_BY_ID_FAILURE = "GET_COMPLIANCE_BY_ID_FAILURE";
export const DELETE_COMPLIANCE_REQUEST = "DELETE_COMPLIANCE_REQUEST";
export const DELETE_COMPLIANCE_SUCCESS = "DELETE_COMPLIANCE_SUCCESS";
export const DELETE_COMPLIANCE_FAILURE = "DELETE_COMPLIANCE_FAILURE";
export const UPDATE_COMPLIANCE_REQUEST = "UPDATE_COMPLIANCE_REQUEST";
export const UPDATE_COMPLIANCE_SUCCESS = "UPDATE_COMPLIANCE_SUCCESS";
export const UPDATE_COMPLIANCE_FAILURE = "UPDATE_COMPLIANCE_FAILURE";
export const SET_COMPLIANCE_PROPERTY_CATEGORY = "SET_COMPLIANCE_PROPERTY_CATEGORY";
export const SET_SINGLE_COMPLIANCE = "SET_SINGLE_COMPLIANCE";
export const GET_ALL_COMPLIANCES_REQUEST = "GET_ALL_COMPLIANCES_REQUEST";
export const GET_ALL_COMPLIANCES_SUCCESS = "GET_ALL_COMPLIANCES_SUCCESS";
export const GET_ALL_COMPLIANCES_FAILURE = "GET_ALL_COMPLIANCES_FAILURE";
export const SET_VIEW_OPTION = "SET_VIEW_OPTION";
export const SET_SINGLE_COMPLIANCE_ALL = "SET_SINGLE_COMPLIANCE_ALL";
export const ARCHIVE_FILE_REQUEST = "ARCHIVE_FILE_REQUEST";
export const ARCHIVE_FILE_FAILURE = "ARCHIVE_FILE_FAILURE";
export const ARCHIVE_FILE_SUCCESS = "ARCHIVE_FILE_SUCCESS";
export const UNARCHIVE_FILE_REQUEST = "UNARCHIVE_FILE_REQUEST";
export const UNARCHIVE_FILE_SUCCESS = "UNARCHIVE_FILE_SUCCESS";
export const UNARCHIVE_FILE_FAILURE = "UNARCHIVE_FILE_FAILURE";
