import { SET_COMPONENT, STOP_LOADING } from "./fullScreenModalTypes";

const initialState = {
  component: null
};

const fullScreenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPONENT:
      return {
        ...state,
        component: action.payload
      };
    case STOP_LOADING:
      return {
        isLoading: false
      };

    default:
      return state;
  }
};

export default fullScreenReducer;
