import {
  TICKET_CATEGORY_FAILURE,
  TICKET_CATEGORY_REQUEST,
  TICKET_CATEGORY_SUCCESS
} from "./types";

const initialState = {
  categories: [],
  loading: false,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case TICKET_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case TICKET_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload.category,
        loading: false
      };
    case TICKET_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    default:
      return state;
  }
};


export default categoryReducer;