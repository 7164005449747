import { useIndexedDB } from "react-indexed-db";
const STORE_NAME = "user";

export const DBConfig = {
  name: "fixify",
  version: 1,
  objectStoresMeta: [
    {
      store: STORE_NAME,
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "email", keypath: "email", options: { unique: false } },
        { name: "firstName", keypath: "firstName", options: { unique: false } },
        { name: "lastName", keypath: "lastName", options: { unique: false } },
        { name: "contact", keypath: "contact", options: { unique: false } },
        { name: "role", keypath: "role", options: { unique: false } },
        { name: "jwtToken", keypath: "jwtToken", options: { unique: false } },
        { name: 'profileImage', keypath: 'profileImage', options:{unique: false}},
        {
          name: "isLoggedIn",
          keypath: "isLoggedIn",
          options: { unique: false }
        },
        {
          name: "isProfileComplete",
          keypath: "isProfileComplete",
          options: { unique: false }
        },
        {
          name: "businessName",
          keypath: "businessName",
          options: { unique: false }
        },
        {
          name: "landlordBusinessName",
          keypath: "landlordBusinessName",
          options: { unique: false }
        }
      ]
    }
  ]
};

//add storage
export async function AddStorage(payload) {
  const { add } = useIndexedDB(STORE_NAME);

  await add(payload);
}

//get storage
export async function GetStorage() {
  const { getByID } = useIndexedDB(STORE_NAME);
  return await getByID(1);
}

//update storage
export async function UpdateStorage(payload) {
  const { update } = useIndexedDB(STORE_NAME);
  let data = payload;
  data.id = 1;
  await update(data);
}

//clear storage
export async function ClearStorage() {
  const { clear } = useIndexedDB(STORE_NAME);

  await clear();
}
