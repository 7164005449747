import {
  UNREAD_COUNT_FAILURE,
  UNREAD_COUNT_REQUEST,
  UNREAD_COUNT_SUCCESS,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_FAILURE,
  GET_MESSAGES_SUCCESS,
  MARK_READ_FAILURE,
  MARK_READ,
  DELETE_MESSAGE,
  DELETE_MESSAGE_FAILURE,
  MARK_READ_TICKET,
  MARK_READ_TICKET_FAILURE,
  MARK_UNREAD,
  MARK_UNREAD_FAILURE
} from "./types";

const setIsRead = (messages, ids) => {
  messages.forEach((item) => {
    item.records.forEach((record) => {
      if (ids.includes(record.notificationId)) {
        record.isRead = true;
      }
    });
  });
  return messages;
};

const setIsUnread = (messages, id) => {
  for (let item of messages) {
    for (let record of item.records) {
      if (id === record.notificationId) {
        record.isRead = false;
        return messages;
      }
    }
  }
};

const setISReadTicket = (messages, id, priv) => {
  let count = 0;
  const findIndex = messages.findIndex((msg) => msg._id === id);
  messages[findIndex].records.forEach((record) => {
    if (record.isPrivate === priv && record.isRead === false) {
      record.isRead = true;
      count++;
    }
  });
  return { messages, count };
};
const deleteMessages = (messages, ids) => {
  messages.forEach((item) => {
    item.records = item.records.filter((record) => !ids.includes(record.notificationId));
  });
  return messages;
};
const initialState = {
  messages: [],
  unreadCount: 0
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNREAD_COUNT_REQUEST:
    case GET_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case MARK_READ_TICKET: {
      const updatedData = setISReadTicket(state.messages, action.payload.ticketId, action.payload.privateComment);
      return {
        ...state,
        loading: false,
        messages: updatedData.messages,
        unreadCount: state.unreadCount - updatedData.count
      };
    }
    case MARK_READ: {
      return {
        ...state,
        loading: false,
        messages: setIsRead(state.messages, action.payload),
        unreadCount: state.unreadCount - action.payload?.length
      };
    }
    case MARK_UNREAD: {
      return {
        ...state,
        loading: false,
        messages: setIsUnread(state.messages, action.payload),
        unreadCount: state.unreadCount + 1
      };
    }
    case DELETE_MESSAGE: {
      return {
        ...state,
        loading: false,
        message: deleteMessages(state.messages, action.payload.payload),
        unreadCount: action.payload.read ? state.unreadCount : state.unreadCount - action.payload?.payload?.length
      };
    }
    case UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        unreadCount: action.payload.unreadCount,
        loading: false
      };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload.messages
      };
    case UNREAD_COUNT_FAILURE:
    case GET_MESSAGES_FAILURE:
    case MARK_READ_FAILURE:
    case MARK_UNREAD_FAILURE:
    case DELETE_MESSAGE_FAILURE:
    case MARK_READ_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default messagesReducer;
