import {
  SET_USER_DATA,
  SET_SIGNUP_PAYLOAD,
  CLEAR_SIGNUP_PAYLOAD,
  SET_USER_ROLE,
  SET_ADDRESSES,
  CREAT_USER_ACCOUNT_FAILURE,
  CREAT_USER_ACCOUNT_REQUEST,
  CREAT_USER_ACCOUNT_SUCCESS,
  SET_STEPS,
  VERIFICATION_FAILURE,
  VERIFICATION_REQUEST,
  VERIFICATION_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  INVITE_LANDLORD_REQUEST,
  INVITE_LANDLORD_SUCCESS,
  INVITE_LANDLORD_FAILURE,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  VERIFY_FORGOT_TOKEN_REQUEST,
  VERIFY_FORGOT_TOKEN_SUCCESS,
  VERIFY_FORGOT_TOKEN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SET_PROFILE_IMAGE,
  SET_MY_DETAILS,
  GET_ACCOUNT_DETAILS_FAILURE,
  GET_ACCOUNT_DETAILS_REQUEST,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_REQUEST,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_FAILURE,
  NEED_PASSWORD_CHANGE
} from "./userTypes";
import http from "../../axios";
import { api } from "_endpoints/api";
import { setError } from "redux/errors/errorActions";
import { removeItemsFromLocalStorage } from "utils/index";
import { ClearStorage, UpdateStorage } from "_services/idb";
import { useIndexedDB } from "react-indexed-db";
import { Roles, Store } from "_enums/enums";
import { setUserDataInLocalStorage } from "_shared/functions";
import { getCompliances } from "redux/compliance/action";
import { getJobs } from "redux/dashboard/action";
const STORE_NAME = "user";

export const createAccount = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREAT_USER_ACCOUNT_REQUEST });
      const response = await http.post(api.auth.signupUrl, payload);
      let userData = response.data.result.user;
      if (payload.role === "Landlord") {
        const items = ["isVerified", "subStep", "step", "signupPayload", "address", "isProfileComplete", "showStepper"];
        removeItemsFromLocalStorage(items);
      } else if (payload.role === "Tenant") {
        dispatch({ type: SET_STEPS, payload: { step: 2, subStep: 0 } });
        localStorage.setItem("isProfileComplete", true);
        UpdateStorage({
          ...response.data.result.user,
          landlordBusinessName: response.data.result.landlordBusinessName,
          jwtToken: response.data.result.jwtToken,
          isProfileComplete: true,
          isLoggedIn: true
        });

        localStorage.setItem(Store.email, response.data.result.user.email);
        localStorage.setItem(Store.token, response.data.result.jwtToken);
        localStorage.setItem(Store.isLoggedIn, true);
        localStorage.setItem(Store.role, response.data.result.user.role);
        localStorage.setItem(Store.userId, response.data.result.user._id);
        localStorage.setItem(Store.businessName, response.data.result.user.businessName);
        localStorage.setItem(Store.landlordBusinessName, response.data.result.landlordBusinessName);

        userData.landlordBusinessName = response.data.result.landlordBusinessName;
      }
      dispatch({ type: CREAT_USER_ACCOUNT_SUCCESS, payload: userData });
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: CREAT_USER_ACCOUNT_FAILURE, error: error.response.data.message });
    }
  };
};
export const verifyEmail = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFICATION_REQUEST });
      const response = await http.post(api.auth.verifyEmailUrl, payload);
      let data = response.data.user;
      data.isLoggedIn = true;
      UpdateStorage(data);
      dispatch({ type: VERIFICATION_SUCCESS, payload: response.data });
    } catch (error) {
      if (
        (error.response.data && error.response.data.message === "profile is complete") ||
        error.response.data.message === "Invalid verification link."
      ) {
        const items = [
          "subStep",
          "step",
          "signupPayload",
          "address",
          "isProfileComplete",
          "showStepper",
          "userId",
          "isLoggedIn",
          "email",
          "token",
          "isVerified"
        ];
        removeItemsFromLocalStorage(items);
        // window.location.href = "/#/not-found";
        window.location.href = "/not-found";
        dispatch({ type: SET_STEPS, payload: { step: 0, subStep: 0 } });
      } else {
        dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
        dispatch({ type: VERIFICATION_FAILURE, error: error.response.data.message });
      }
    }
  };
};

export const updateUser = (id, payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_REQUEST });
      const response = await http.post(api.user.updateUserUrl + id, payload);
      // window.location.href = "/#/Dashboard";
      const items = ["signupPayload", "address", "showStepper", "isVerified"];
      removeItemsFromLocalStorage(items);
      dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: UPDATE_USER_FAILURE, error: error.response.data.message });
    }
  };
};

export const inviteLandlord = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INVITE_LANDLORD_REQUEST });
      const response = await http.post(api.user.inviteLandlord, payload);
      const items = ["signupPayload", "address", "showStepper", "isVerified"];
      removeItemsFromLocalStorage(items);
      dispatch({ type: INVITE_LANDLORD_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: INVITE_LANDLORD_FAILURE, error: error.response.data.message });
    }
  };
};

export const forgotPassword = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });
      await http.post(api.auth.forgotPasswordUrl, payload);
      dispatch({ type: FORGOT_PASSWORD_SUCCESS });
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: FORGOT_PASSWORD_FAILURE, error: error.response.data.message });
    }
  };
};

export const verfiyResetToken = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFY_FORGOT_TOKEN_REQUEST });
      await http.post(api.auth.verifyResetPassUrl, payload);
      dispatch({ type: VERIFY_FORGOT_TOKEN_SUCCESS });
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isopen: true, duration: 6000, saverity: "warning" }]));

      dispatch({ type: VERIFY_FORGOT_TOKEN_FAILURE, error: error.response.data.message });
    }
  };
};

export const resetPassword = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESET_PASSWORD_REQUEST });
      await http.post(api.auth.resetPassUrl, payload);
      dispatch({ type: RESET_PASSWORD_SUCCESS });
    } catch (error) {
      dispatch(setError([{ msg: error.response.data.message, isopen: true, duration: 6000, saverity: "warning" }]));
      dispatch({ type: RESET_PASSWORD_FAILURE, error: error.response.data.message });
    }
  };
};
export const loginUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const response = await http.post(api.auth.loginUrl, payload);
      const data = response.data;
      if (data.liaisonFirstLogin) {
        localStorage.setItem("liaisonFirstLogin", data.liaisonFirstLogin);
        localStorage.setItem("userId", data._id);
        dispatch({ type: NEED_PASSWORD_CHANGE, payload: { firstLogin: true, userId: data._id } });
      } else {
        data.isLoggedIn = true;
        data.isProfileComplete = true;
        setUserDataInLocalStorage(data);
        UpdateStorage({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          role: data.role,
          id: data._id,
          jwtToken: data.jwtToken,
          isProfileComplete: true,
          isLoggedIn: true,
          businessName: data.businessName,
          createdAt: data.role === Roles.TradesPerson ? data.memberSince : data.createdAt,
          updatedAt: data.updatedAt,
          contact: data.contact,
          profileImage: data.profileImage,
          landlordBusinessName: data?.landlordBusinessName ? data.landlordBusinessName : ""
        });
        dispatch({ type: LOGIN_SUCCESS, payload: data });
        dispatch(getCompliances());
        dispatch(getJobs());
      }
    } catch (err) {
      console.log(err);
      dispatch(setError([{ msg: err.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: LOGIN_FAIL, error: err.response.data.message });
    }
  };
};
export const logiGoogle = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const response = await http.post(api.auth.googleLoginUrl, payload);
      const data = response.data;
      if (data.liaisonFirstLogin) {
        localStorage.setItem("liaisonFirstLogin", data.liaisonFirstLogin);
        localStorage.setItem("userId", data._id);
        dispatch({ type: NEED_PASSWORD_CHANGE, payload: { firstLogin: true, userId: data._id } });
      } else {
        data.isLoggedIn = true;
        data.isProfileComplete = true;
        setUserDataInLocalStorage(data);
        UpdateStorage({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          id: data._id,
          role: data.role,
          jwtToken: data.jwtToken,
          isProfileComplete: true,
          isLoggedIn: true,
          businessName: data.businessName,
          createdAt: data.role === Roles.TradesPerson ? data.memberSince : data.createdAt,
          updatedAt: data.updatedAt,
          contact: data.contact,
          profileImage: data.profileImage
        });
        dispatch({ type: LOGIN_SUCCESS, payload: data });
        dispatch(getCompliances());
        dispatch(getJobs());
      }
    } catch (err) {
      dispatch(setError([{ msg: err.response.data.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: LOGIN_FAIL, error: err.response.data.message });
    }
  };
};
export const getAccountDetails = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ACCOUNT_DETAILS_REQUEST });
      const response = await http.get(api.user.getAccountDetails);
      const data = response.data;
      dispatch({ type: GET_ACCOUNT_DETAILS_SUCCESS, payload: data });
    } catch (err) {
      console.log(err);
      dispatch(setError([{ msg: err?.response?.data?.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: GET_ACCOUNT_DETAILS_FAILURE, error: err?.response?.data?.message });
    }
  };
};

export const getSubscriptionDetails = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBSCRIPTION_DETAILS_REQUEST });
      const response = await http.get(api.user.getSubscriptionDetails);
      const data = response.data;
      dispatch({ type: GET_SUBSCRIPTION_DETAILS_SUCCESS, payload: data });
    } catch (err) {
      console.log(err);
      dispatch(setError([{ msg: err?.response?.data?.message, isOpen: true, duration: 6000, severity: "warning" }]));
      dispatch({ type: GET_SUBSCRIPTION_DETAILS_FAILURE, error: err?.response?.data?.message });
    }
  };
};
export const setSteps = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_STEPS, payload: payload });
  };
};
export const setUserData = (payload) => {
  return {
    type: SET_USER_DATA,
    payload: payload
  };
};

export const setSignUpPayload = (payload) => {
  return {
    type: SET_SIGNUP_PAYLOAD,
    payload: payload
  };
};

export const clearSignUpPayload = () => {
  return {
    type: CLEAR_SIGNUP_PAYLOAD
  };
};

export const setUserRole = (payload) => {
  return {
    type: SET_USER_ROLE,
    payload: payload
  };
};

export const setAddress = (payload) => {
  return {
    type: SET_ADDRESSES,
    payload: payload
  };
};
export const profileImageUpload = (payload) => {
  return async (dispatch) => {
    try {
      // update index DB
      const { getByID, update } = useIndexedDB(STORE_NAME);
      // Fetch the existing record by its primary key
      const existingRecord = await getByID(1);
      const updatedData = {
        data: { ...existingRecord, profileImage: payload.profileImage }
      };
      await update(updatedData.data);
      // update localstorage and state
      dispatch({ type: SET_PROFILE_IMAGE, payload: payload });
    } catch (err) {
      console.log(err);
    }
  };
};
export const myDetailsUpdate = (payload) => {
  return async (dispatch) => {
    try {
      setUserDataInLocalStorage(payload);
      // update index DB
      const { getByID, update } = useIndexedDB(STORE_NAME);
      // Fetch the existing record by its primary key
      const existingRecord = await getByID(1);
      const updatedData = {
        data: {
          ...existingRecord,
          firstName: payload.firstName,
          lastName: payload.lastName,
          businessName: payload.businessName,
          contact: payload.contact,
          jwtToken: payload.jwtToken
        }
      };
      await update(updatedData.data);
      // update localstorage and state
      dispatch({ type: SET_MY_DETAILS, payload: payload });
    } catch (err) {
      console.log(err);
    }
  };
};

export const userLogout = () => {
  return async (dispatch) => {
    try {
      localStorage.clear();
      ClearStorage().then(() => {
        // window.location.href = "/#/login";
        window.location.href = "/login";
      });
      dispatch({ type: "USER_LOGOUT" });
    } catch (err) {
      console.log(err);
    }
  };
};
