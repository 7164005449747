export const removeItemsFromLocalStorage = (items) => {
  for (let i = 0; i < items.length; i++) {
    localStorage.removeItem(items[i]);
  }
};

export const setItemsToLocalStorage = (items) => {
  for (let i = 0; i < items.length; i++) {
    localStorage.setItem(items[i].name, JSON.stringify(items[i].value));
  }
};
