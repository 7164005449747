import { RouteNames } from "_route-names/route-names";
import {
  LISTING_COMPLIANCES_REQUEST,
  LISTING_COMPLIANCES_SUCCESS,
  LISTING_COMPLIANCES_FAILURE,
  ADD_COMPLIANCE_REQUEST,
  ADD_COMPLIANCE_SUCCESS,
  ADD_COMPLIANCE_FAILURE,
  GET_COMPLIANCE_BY_ID_REQUEST,
  GET_COMPLIANCE_BY_ID_SUCCESS,
  DELETE_COMPLIANCE_REQUEST,
  DELETE_COMPLIANCE_SUCCESS,
  DELETE_COMPLIANCE_FAILURE,
  UPDATE_COMPLIANCE_REQUEST,
  UPDATE_COMPLIANCE_FAILURE,
  SET_COMPLIANCE_PROPERTY_CATEGORY,
  SET_SINGLE_COMPLIANCE,
  GET_ALL_COMPLIANCES_FAILURE,
  GET_ALL_COMPLIANCES_SUCCESS,
  SET_VIEW_OPTION,
  SET_SINGLE_COMPLIANCE_ALL,
  ARCHIVE_FILE_REQUEST,
  ARCHIVE_FILE_FAILURE,
  ARCHIVE_FILE_SUCCESS,
  UPDATE_COMPLIANCE_SUCCESS,
  UNARCHIVE_FILE_FAILURE,
  UNARCHIVE_FILE_REQUEST,
  UNARCHIVE_FILE_SUCCESS
} from "./types";

const initialState = {
  compliances: [],
  loading: false,
  singleProperty: JSON.parse(localStorage.getItem("singleProperty")) || null,
  singleCompliance: JSON.parse(localStorage.getItem("singleCompliance")) || null,
  allCompliances: [],
  viewOption: JSON.parse(localStorage.getItem("viewOption")) || "By Property",
  redirectedFrom: "property"
};

const compliancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTING_COMPLIANCES_REQUEST:
    case ADD_COMPLIANCE_REQUEST:
    case DELETE_COMPLIANCE_REQUEST:
    case GET_COMPLIANCE_BY_ID_REQUEST:
    case UPDATE_COMPLIANCE_REQUEST:
    case ARCHIVE_FILE_REQUEST:
    case UNARCHIVE_FILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LISTING_COMPLIANCES_SUCCESS:
      return {
        ...state,
        compliances: action.payload,
        loading: false
      };

    case LISTING_COMPLIANCES_FAILURE:
    case ADD_COMPLIANCE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    case ADD_COMPLIANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        compliances: ((action, state) => {
          const compliances = [...state.compliances];
          const index = compliances.findIndex((e) => e._id === action.payload.propertyId);
          compliances[index].compliances.push(action.payload);
          compliances[index].counts[determineStatus(action.validTo, action.active)] += 1;
          return compliances;
        })(action, state),
        singleProperty: ((state, action) => {
          const property = { ...state.singleProperty };
          const index = property.compliances.findIndex((e) => e._id === action.payload._id);
          if (index === -1) property.compliances.push(action.payload);
          localStorage.setItem("singleProperty", JSON.stringify(property));
          return property;
        })(state, action)
      };
    case GET_COMPLIANCE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCompliance: action.payload
      };
    case DELETE_COMPLIANCE_SUCCESS:
      localStorage.removeItem("singleCompliance");
      localStorage.setItem("current-menue", JSON.stringify(RouteNames.complaince + "/" + action.payload.propertyId));
      return {
        ...state,
        loading: false,
        success: true,
        singleProperty: ((state, action) => {
          const property = { ...state.singleProperty };
          const index = property.compliances.findIndex((e) => e._id === action.payload.id);
          property.counts[determineStatus(property.compliances[index].validTo, property.compliances[index].active)] -= 1;
          property.compliances.splice(index, 1);
          localStorage.setItem("singleProperty", JSON.stringify(property));
          return property;
        })(state, action)
      };
    case SET_COMPLIANCE_PROPERTY_CATEGORY:
      localStorage.setItem("singleProperty", JSON.stringify(state.compliances.find((e) => e._id === action.payload)));
      return {
        ...state,
        singleProperty: state.compliances.find((e) => e._id === action.payload)
      };
    case SET_SINGLE_COMPLIANCE:
      localStorage.setItem(
        "singleCompliance",
        JSON.stringify({ ...state.singleProperty.compliances.find((e) => e._id === action.payload), property: state.singleProperty })
      );
      return {
        ...state,
        singleCompliance: { ...state.singleProperty.compliances.find((e) => e._id === action.payload), property: state.singleProperty },
        redirectedFrom: "property",
        loading: false
      };

    case SET_SINGLE_COMPLIANCE_ALL:
      localStorage.setItem(
        "singleCompliance",
        JSON.stringify({
          ...state.allCompliances.find((e) => e._id === action.payload),
          property: state.allCompliances.find((e) => e._id === action.payload).propertyId
        })
      );
      return {
        ...state,
        singleCompliance: {
          ...state.allCompliances.find((e) => e._id === action.payload),
          property: state.allCompliances.find((e) => e._id === action.payload).propertyId
        },
        redirectedFrom: "compliances",
        loading: false
      };

    case GET_ALL_COMPLIANCES_SUCCESS:
      return {
        ...state,
        allCompliances: action.payload
      };

    case ARCHIVE_FILE_SUCCESS:
    case UPDATE_COMPLIANCE_SUCCESS:
    case UNARCHIVE_FILE_SUCCESS:
      localStorage.setItem("singleCompliance", JSON.stringify({ ...action.payload, property: action.payload.propertyId }));
      return {
        ...state,
        singleCompliance: { ...action.payload, property: action.payload.propertyId },
        loading: false,
        singleProperty: ((state, action) => {
          const property = { ...state.singleProperty };
          const index = property.compliances.findIndex((e) => e._id === action.payload._id);
          property.compliances[index] = action.payload;
          localStorage.setItem("singleProperty", JSON.stringify(property));
          return property;
        })(state, action)
      };
    case SET_VIEW_OPTION:
      localStorage.setItem("viewOption", JSON.stringify(action.payload));
      return {
        ...state,
        viewOption: action.payload
      };
    case DELETE_COMPLIANCE_FAILURE:
    case UPDATE_COMPLIANCE_FAILURE:
    case GET_ALL_COMPLIANCES_FAILURE:
    case ARCHIVE_FILE_FAILURE:
    case UNARCHIVE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default compliancesReducer;

function determineStatus(validTo, active) {
  const currentDate = new Date();
  const dueDate = new Date(validTo);
  if (dueDate < currentDate && active) {
    return "overdue";
  } else if (dueDate <= new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000) && active) {
    return "dueIn30Days";
  } else if (dueDate >= new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000) && active) {
    return "current";
  }
}
