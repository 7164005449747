import { SET_SNACKBAR_DATA } from "./snackbarTypes";
import { SnackbarSeverity } from "_enums/enums";

const initialState = {
  snackbarData: {
    msg: "",
    severity: SnackbarSeverity.info,
    isOpen: false,
    duration: 5000
  }
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR_DATA:
      return {
        ...state,
        snackbarData: action.payload
      };

    default:
      return state;
  }
};

export default snackbarReducer;
