export const FETCH_ADDRESSESS_REQUEST = "FETCH_ADDRESSESS_REQUEST";
export const FETCH_ADDRESSESS_FAIL = "FETCH_ADDRESSESS_FAIL";
export const FETCH_ADDRESSESS_SUCCESS = "FETCH_ADDRESSESS_SUCCESS";
export const CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY_REQUEST";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const CREATE_PROPERTY_FAIL = "CREATE_PROPERTY_FAIL";
export const GET_ALL_LISTING_REQUEST = "GET_ALL_LISTING_REQUEST";
export const GET_ALL_LISTING_SUCCESS = "GET_ALL_SUCCESS";
export const GET_ALL_LISTING_FAIL = "GET_ALL_LISTING_FAIL";
export const GET_SINGLE_PROPERTY_REQUEST = "GET_SINGLE_PROPERTY_REQUEST";
export const GET_SINGLE_PROPERTY_SUCCESS = "GET_SINGLE_PROPERTY_SUCCESS";
export const GET_SINGLE_PROPERTY_FAIL = "GET_SINGLE_PROPERTY_FAIL";
export const INVITE_TENANTS_REQUEST = "INVITE_TENANTS_REQUEST";
export const INVITE_TENANTS_SUCCESS = "INVITE_TENANTS_SUCCESS";
export const INVITE_TENANTS_FAIL = "INVITE_TENANTS_FAIL";
export const SEND_CREATE_ACCOUNT_REMINDER_REQUEST = "SEND_CREATE_ACCOUNT_REMINDER_REQUEST";
export const SEND_CREATE_ACCOUNT_REMINDER_SUCCESS = "SEND_CREATE_ACCOUNT_REMINDER_SUCCESS";
export const SEND_CREATE_ACCOUNT_REMINDER_FAIL = "SEND_CREATE_ACCOUNT_REMINDER_FAIL";
export const DELETE_TENANTS_FROM_PROPERTY_REQUEST = "DELETE_TENANTS_FROM_PROPERTY_REQUEST";
export const DELETE_TENANTS_FROM_PROPERTY_SUCCESS = "DELETE_TENANTS_FROM_PROPERTY_SUCCESS";
export const DELETE_TENANTS_FROM_PROPERTY_FAIL = "DELETE_TENANTS_FROM_PROPERTY_FAIL";
export const UPDATE_TENANCY_PERIOD_REQUEST = "UPDATE_TENANCY_PERIOD_REQUEST";
export const UPDATE_TENANCY_PERIOD_SUCCESS = "UPDATE_TENANCY_PERIOD_SUCCESS";
export const UPDATE_TENANCY_PERIOD_FAIL = "UPDATE_TENANCY_PERIOD_FAIL";
export const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_FAIL = "UPDATE_PROPERTY_FAIL";
