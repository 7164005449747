import React, { Component, Fragment, Suspense, lazy } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
/***
 * import configureStore from "./config/configureStore";
 * import { Provider } from "react-redux";
 * import Routes from "./Routes";
 * import CssBaseline from "@material-ui/core/CssBaseline";
 * import { BASE_URL } from './environment';
 * import { HashRouter } from "react-router-dom";
 * import IntialStateSet from "./intialStateSet";
 * import ShowSnackbar from "./_shared/show-snackbar.jsx";
 * import { messaging } from "./init-fcm";
 * import { stat } from "fs-extra";
 * import ShowNotification from "_shared/show-notification";
 * const store = configureStore();
 * import { SendNotificationData } from "_shared/functions";
 *
 */

import ScrollToTop from "./utils/ScrollToTop";
import "./assets/base.scss";

import { DBConfig } from "./_services/idb";
import { initDB } from "react-indexed-db";
import { library } from "@fortawesome/fontawesome-svg-core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
} from "@fortawesome/free-brands-svg-icons";
import {
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
} from "@fortawesome/free-regular-svg-icons";
import {
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import ShowError from "_shared/error";
import { getJobs } from "redux/dashboard/action";
import { getCompliances } from "redux/compliance/action";
library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
);
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
);
library.add(
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
);

const Routes = lazy(() => import("./Routes"));
const IntialStateSet = lazy(() => import("./intialStateSet"));
const ShowSnackbar = lazy(() => import("./_shared/show-snackbar.jsx"));
const ShowNotification = lazy(() => import("_shared/show-notification"));
const CssBaseline = lazy(() => import("@material-ui/core/CssBaseline"));
class App extends Component {
  constructor() {
    super();
    initDB(DBConfig);
  }

  componentDidMount() {
    this.props.getJobs();
    this.props.getCompliances();
  }
  render() {
    const { isAuthenticated, isProfileComplete } = this.props;
    const step = JSON.parse(localStorage.getItem("step"));
    const currentMenue = JSON.parse(localStorage.getItem("current-menue"));

    return (
      // <Provider store={store}>
      // <div></div>
      <Fragment>
        <BrowserRouter>
          <ScrollToTop>
            <Suspense fallback={<div></div>}>
              <Route
                path="/"
                render={() => (isAuthenticated && isProfileComplete && !step && !!currentMenue ? <Redirect to={`${currentMenue}`} /> : null)}
              />
              <Route path="/" render={() => (isAuthenticated && isProfileComplete && !step && !currentMenue ? <Redirect to="/Dashboard" /> : null)} />

              <Routes />
            </Suspense>
            {/* <Routes /> */}
          </ScrollToTop>
          <Suspense fallback={<div></div>}>
            <CssBaseline />
          </Suspense>
        </BrowserRouter>
        <Suspense fallback={<div></div>}>
          <ShowSnackbar />
          <ShowError />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <ShowNotification />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <IntialStateSet />
        </Suspense>
      </Fragment>
      // </Provider>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.userData.isLoggedIn,
  isProfileComplete: state.user.userData.isProfileComplete
});

const mapDispatchToProps = (dispatch) => ({
  getJobs: () => dispatch(getJobs()),
  getCompliances: () => dispatch(getCompliances())
});

App.propTypes = {
  getJobs: PropTypes.func.isRequired,
  getCompliances: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isProfileComplete: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
