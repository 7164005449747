import { Store } from "_enums/enums";
import Moment from "moment";
import { ReplaySubject } from "rxjs";
import { Subject } from "rxjs";

export function setUserDataInLocalStorage(userData) {
  localStorage.setItem(Store.token, userData.jwtToken);
  localStorage.setItem(Store.role, userData.role);
  localStorage.setItem(Store.email, userData.email);
  localStorage.setItem(Store.isProfileComplete, userData.isProfileComplete);
  localStorage.setItem(Store.isLoggedIn, true);
  localStorage.setItem(Store.userId, userData._id);
  localStorage.setItem(Store.businessName, userData.businessName);
  localStorage.setItem(Store.createdAt, userData.createdAt);
  localStorage.setItem(Store.updatedAt, userData.updatedAt);
  localStorage.setItem(Store.contact, userData.contact);
  localStorage.setItem(Store.profileImage,userData.profileImage);
  localStorage.setItem(Store.firstName, userData.firstName);
  localStorage.setItem(Store.lastName, userData.lastName);
  localStorage.setItem(Store.landlordBusinessName, userData.landlordBusinessName);
}

export function GetValueByKeys(keys, arrayOfObj) {
  let values = [];
  arrayOfObj.forEach((obj) => {
    for (let objKey of Object.keys(obj)) {
      if (keys.includes(objKey)) {
        values.push(obj[objKey]);
      }
    }
  });
  return values;
}

//datepipe
export function DatePipe(date, format = "do, MMM YYYY, h:mm A") {
  Moment.locale("en");
  return Moment(date).format(format);
}
//timepipe
export function OnlyTimePipe(date, format = "h:mm A") {
  Moment.locale("en");
  return Moment(date).format(format);
}
//only date pipe format("dddd, MMMM Do YYYY, h:mm:ss a"); // "Sunday, February 14th 2010, 3:25:50 pm"
export function OnlyDatePipe(date, format = "ddd, DD MMM YYYY") {
  Moment.locale("en");
  return Moment(date).format(format);
}
export function CustomDateFormat(date, format = "ddd, DD MMM YYYY, h:mm") {
  Moment.locale("en");
  return Moment(date).format(format);
}
export function Time24Hour(date) {
  return OnlyDatePipe(date) + ", " + new Date(date).toTimeString().split(" ")[0].split(":").splice(0, 2).join(":");
}
//pagetitle replay subject
export let PageTitle$ = new ReplaySubject(1);
export function SetPageTitle(title = "testing") {
  PageTitle$.next(title);
}

//annoument add status
export let IsAnnouncementAdded$ = new ReplaySubject(1);
export function SetAnnouncementAddStatus(status) {
  IsAnnouncementAdded$.next(status);
}

//hide hover sidebar on route change
export let IsSidebarLinkClicked$ = new ReplaySubject(1);
export function HideHoverSidebar(status) {
  IsSidebarLinkClicked$.next(status);
}

//notification data
export let Notification$ = new ReplaySubject(1);
export function SendNotificationData(notification) {
  Notification$.next(notification);
}

//loading spinner data
export let LoadingSpinnerStatus$ = new ReplaySubject(1);
export function SetLoadingStatus() {
  /**
   * LoadingSpinnerStatus$.next(status);
   *  */
}

//full screen modal
export let FullScreenModalData$ = new Subject(null);
export function SetFullScreenModalComponent(c) {
  FullScreenModalData$.next(c);
}

export function TimeToDate(time) {
  let t = time.split(":");
  let d = new Date();
  d.setHours(t[0]);
  d.setMinutes(t[1]);
  d.setSeconds(0);
  return d;
}
